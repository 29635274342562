import React, { useState, useEffect, useRef } from 'react';
import {
    HStack,
    VStack,
    Button,
    chakra,
    Box,
    Center,
    Image,
    useCheckbox,
    Text,
    useCheckboxGroup,
    Input,
    Icon,
    IconButton,
    Heading,
    useToast,
    useColorModeValue,
    Menu,
    MenuButton,
    Flex,
    MenuList,
    MenuItem,
    SimpleGrid,
    Badge,
    Modal,
    ModalOverlay,
    ModalContent,
    Divider,
    Popover,
    PopoverTrigger,
    PopoverContent,
} from '@chakra-ui/react';
import {searchTmdbFilms, searchPlaylists, searchProfiles, fetchGenres, getTopFilmsByCategoryAndGenre, getFilmsByGenres, getNotifications, markNotificationsAsRead} from '../api';
import CustomSwitch from "./CustomSwitch";
import { useNavigate } from 'react-router-dom';
import ActivityPopover from './ActivityPopover';
import ProfileImage from './ProfileImage';
import MobileNotifications from './MobileActivity';
import MobileActivity from './MobileActivity';


function CustomCheckbox(props) {
    const { state, getInputProps, getLabelProps, htmlProps } =
        useCheckbox(props);
    
    if (state.isChecked) {
        return (
            <chakra.label
                display='flex'
                flexDirection='row'
                alignItems='center'
                gridColumnGap={2}
                bg='black.50'
                border='3px solid'
                borderColor='qPurple.500'
                rounded='lg'
                px={3}
                py={1}
                cursor='pointer'
                {...htmlProps}
            >
                <input {...getInputProps()} hidden />
                <Text
                    color={useColorModeValue('black', 'white')}
                    fontFamily='categoryFont'
                    letterSpacing="widest"
                    fontWeight='semibold'
                    {...getLabelProps()}
                    fontSize={'md'}
                    >    
                    {props.value}
                </Text>
            </chakra.label>
        )
    }
    return (
        <chakra.label
            display='flex'
            flexDirection='row'
            alignItems='center'
            gridColumnGap={2}
            bg='black.50'
            border='3px solid'
            // borderColor='black'
            borderColor={useColorModeValue('white', 'black')}
            rounded='lg'
            px={3}
            py={1}
            cursor='pointer'
            variant = {['mobile','baseStyle']}
            {...htmlProps}
        >
            <input {...getInputProps()} hidden />
            <Text
                // color="white"
                color={useColorModeValue('black', 'white')}
                fontFamily='categoryFont'
                letterSpacing="widest"
                fontWeight='semibold'
                // fontSize= '18px'
                {...getLabelProps()}
                fontSize={'md'}
                >
                
                {props.value}
            </Text>
        </chakra.label>
    )
}

function CategoryCheckbox(setCategories, homeClicked, setHomeClicked, isVisible, searchType, searchSubmitted, setSearchSubmitted) {
    const { value, getCheckboxProps, setValue } = useCheckboxGroup({});

    useEffect(() => {
        setCategories(value);
    }, [value]);

    useEffect(() => {
        setValue([]);
        setHomeClicked(false);
    }, [homeClicked]);

    useEffect(() => {
        setValue([]);
        setCategories([]);
    }, [searchType]);

    useEffect(() => {
        setValue([]);
        setCategories([]);
        setSearchSubmitted(false);
    }, [searchSubmitted]);

    if (!isVisible) return null;

    return (
        <VStack paddingTop='6' width='96%' spacing='' align='end' paddingBottom='4' >
            <CustomCheckbox {...getCheckboxProps({ value: 'Top Plot' })} />
            <CustomCheckbox {...getCheckboxProps({ value: 'Top Acting' })} />
            <CustomCheckbox {...getCheckboxProps({ value: 'Top Cinematography' })} />
            <CustomCheckbox {...getCheckboxProps({ value: 'Top Novelty' })} />
            <CustomCheckbox {...getCheckboxProps({ value: 'Top Ending' })} />
        </VStack>
    )
}

const MobileTopNav = (props) => {

    const {
        setScene,
        loggedIn,
        handleLogout,
        setSearchFilmsResponse,
        searchFilmsResponse,
        customSwitchChecked,
        setCustomSwitchChecked,
        toggleColorMode,
        setFilterIsLoading,
        searchType,
        setSearchType,
        userId
    } = props;

    const [userSearchInput, setUserSearchInput] = useState('');
    const [categories, setCategories] = useState([]);
    const [homeClicked, setHomeClicked] = useState(false);
    const [genres, setGenres] = useState([]);
    const [showGenres, setShowGenres] = useState(false);
    const [selectedGenres, setSelectedGenres] = useState([]);
    const toast = useToast();
    const [toastDisplayed, setToastDisplayed] = useState(false);
    const [searchSubmitted, setSearchSubmitted] = useState(false);
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const isChangingSearchType = useRef(false);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [notifications, setNotifications] = useState([]);
    const [isActivityOpen, setIsActivityOpen] = useState(false);
    const isMounted = useRef(true);
    const isSearching = useRef(false);

    // Add debounce function
    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func.apply(null, args);
            }, delay);
        };
    };

    const handleScroll = () => {
        if (loading) return;
        
        if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 200 && hasMore) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    // Debounce the scroll event
    useEffect(() => {
        const debouncedHandleScroll = debounce(handleScroll, 200);
        window.addEventListener('scroll', debouncedHandleScroll);
        return () => window.removeEventListener('scroll', debouncedHandleScroll);
    }, [hasMore, loading, selectedGenres, userSearchInput]);

    // LIGHT/DARK UI declarations
    // const value = useColorModeValue(lightModeValue, darkModeValue)
    // const borderBottomSearch = useColorModeValue('blue', 'red.200');
    // size is working based on this system, something about border will not let it get implimented, also possible to do
    //a variation of this through the theme.js file
    // const size = useColorModeValue('sm', 'lg');

    const CustomMenuIcon = () => (
        <img src={process.env.PUBLIC_URL + '/MenuWhite.svg'}
        width="50"
        height="50"
        alt="Menu Icon" />
      );

    const CustomMenuIconBlack = () => (
        <img src={process.env.PUBLIC_URL + '/MenuBlack.svg'}
        width="50"
        height="50"
        alt="Menu Icon" />
    );

    const onChangeKeyword = (event) => {
        setUserSearchInput(event.target.value);
    };

    const onSearch = async (event) => {
        event.preventDefault();
        setFilterIsLoading(true);
        setCurrentPage(1);
        setHasMore(true);
        setLoading(false);
        setSearchSubmitted(true);
        isSearching.current = true;  // Set the ref to true before search
        
        try {
            let searchResults;
            if (searchType === 'films') {
                if (!userSearchInput.trim()) {
                    toast({
                        title: "Empty Film Search",
                        description: "Please enter a search term to find films.",
                        status: "warning",
                        duration: 3000,
                        isClosable: true,
                    });
                    setFilterIsLoading(false);
                    if (selectedGenres.length > 0) {
                        fetchResults(1, true);
                    }
                    return;
                }
                const films = await searchTmdbFilms(userSearchInput, selectedGenres);
                searchResults = films;
            } else if (searchType === 'playlists') {
                const playlists = await searchPlaylists(userSearchInput);
                searchResults = { results: playlists };
            } else if (searchType === 'profiles') {
                const profiles = await searchProfiles(userSearchInput);
                searchResults = profiles;
            }
            setSearchFilmsResponse(searchResults.results || []);
            setScene('SEARCH_RESULTS');
        } catch (error) {
            console.error('Search error:', error);
            toast({
                title: "Search Error",
                description: "An error occurred while searching. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setFilterIsLoading(false);
            setTimeout(() => {
                isSearching.current = false;
            }, 0);
        }
    };

    const onHomeClick = () => {
        document.querySelector("input").value = "";
        setCategories([]);
        setScene('HOME');
        setUserSearchInput('');
        setSelectedGenres([]);
        setSearchFilmsResponse([]);
        setHomeClicked(true);
        setSearchType('films');
        setToastDisplayed(false);
        setShowGenres(false); 
    }

    useEffect(() => {
        const fetchGenreData = async () => {
            try {
                const genreData = await fetchGenres();
                setGenres(genreData.genres);
            } catch (error) {
                console.error("Error fetching genres:", error);
            }
        };
        fetchGenreData();
    }, []);

    const fetchResults = async (page, clearResults = false) => {
        if (loading) return;
        setLoading(true);
        try {
            let newResults;
            if (userSearchInput.trim()) {
                // If we have a search term, fetch search results for the current page
                const searchResponse = await searchTmdbFilms(userSearchInput, selectedGenres, page);
                newResults = searchResponse.results;
            } else {
                // Otherwise just fetch genre-filtered results
                newResults = await getFilmsByGenres(selectedGenres, page);
            }
            
            if (clearResults) {
                setSearchFilmsResponse(newResults || []);
            } else {
                const currentResults = searchFilmsResponse || [];
                // More strict duplicate checking
                const uniqueResults = (newResults || []).filter(newItem => 
                    !currentResults.some(existingItem => 
                        existingItem.id === newItem.id || 
                        existingItem.title === newItem.title
                    )
                );
                
                if (uniqueResults.length === 0) {
                    setHasMore(false);
                } else {
                    setSearchFilmsResponse(prevResults => {
                        const results = [...(prevResults || [])];
                        // Double-check for duplicates before adding
                        uniqueResults.forEach(newItem => {
                            if (!results.some(item => item.id === newItem.id)) {
                                results.push(newItem);
                            }
                        });
                        return results;
                    });
                }
            }
            setHasMore((newResults || []).length > 0);
        } catch (error) {
            console.error('Error fetching films:', error);
            setHasMore(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // Reset states when genres change
        setSearchFilmsResponse([]);
        setCurrentPage(1);
        // Only enable infinite scroll if we're not in search mode
        setHasMore(!userSearchInput || !userSearchInput.trim());
        setLoading(false);

        // Only fetch results if we have selected genres
        if (!isInitialLoad && categories.length === 0 && selectedGenres.length > 0) {
            // If we're in search mode, use search function
            if (userSearchInput && userSearchInput.trim()) {
                onSearch({ preventDefault: () => {} });
            } else {
                // Otherwise use genre filtering with infinite scroll
                fetchResults(1, true);
            }
        } else {
            setIsInitialLoad(false);
        }
    }, [selectedGenres]);

    useEffect(() => {
        // Only fetch next page if we're in genre-only mode (no search term)
        if ((!userSearchInput || !userSearchInput.trim()) && selectedGenres.length > 0 && categories.length === 0 && !isInitialLoad) {
            fetchResults(currentPage);
        }
    }, [currentPage]);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        // skip the effect if we're currently performing a search or changing search type
        if (isSearching.current || isChangingSearchType.current) {
            return;
        }

        // Skip if we're not in films search type
        if (searchType !== 'films') {
            return;
        }

        const onCategoryAndGenreFilter = async () => {
            try {
                document.querySelector("input").value = "";
                setFilterIsLoading(true);
                
                if (categories.length === 0 && selectedGenres.length === 0) {
                    setScene('HOME');
                    setSearchFilmsResponse([]);
                } else if (selectedGenres.length > 0 && categories.length === 0) {
                    setScene('SEARCH_RESULTS');
                    await fetchResults(1, true);
                } else {
                    setScene('SEARCH_RESULTS');
                    const films = await getTopFilmsByCategoryAndGenre(categories, selectedGenres);
                    setSearchFilmsResponse(films);
                    
                    if (!toastDisplayed) {
                        toast({
                            title: "Top Category Filters",
                            description: "Display films rated (Well Above Average - 4) in the selected categories. Click to filter.",
                            status: "success",
                            duration: 12000,
                            isClosable: true
                        });
                        setToastDisplayed(true);
                    }
                }
            } catch (e) {
                console.error("Error in category and genre filtering:", e);
            } finally {
                setFilterIsLoading(false);
            }
        };
        
        onCategoryAndGenreFilter();
    }, [categories, selectedGenres, searchType]);

    const handleSearchTypeToggle = async (newType) => {
        isChangingSearchType.current = true;
        setSearchType(newType);
        setUserSearchInput('');
        setSearchFilmsResponse([]);
        setShowGenres(false);
        // this setSelectedGenres is crucial for the infinite scroll to work on playlists and profiles without
        // the extra blank cards getting tacked on to the end of the list
        setSelectedGenres([]);
        
        if (newType === 'playlists' || newType === 'profiles') {
            setFilterIsLoading(true);
            try {
                let searchResults;
                if (newType === 'playlists') {
                    const playlists = await searchPlaylists('');
                    searchResults = { results: playlists };
                } else {
                    const profiles = await searchProfiles('');
                    searchResults = profiles;
                }
                setSearchFilmsResponse(searchResults.results);
                setScene('SEARCH_RESULTS');
            } catch (error) {
                console.error(`Error fetching ${newType}:`, error);
                toast({
                    title: "Error",
                    description: `Failed to load ${newType}.`,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } finally {
                setFilterIsLoading(false);
                setTimeout(() => {
                    isChangingSearchType.current = false;
                }, 0);
            }
        } else {
            setScene('HOME');
            setTimeout(() => {
                isChangingSearchType.current = false;
            }, 0);  
        }
    };

    const toggleGenreVisibility = () => {
        setShowGenres(!showGenres);
        setSelectedGenres([]);
    };

    const handleGenreChange = (genre) => {
        setSelectedGenres(prevGenres => {
            const newGenres = prevGenres.includes(genre)
                ? prevGenres.filter(g => g !== genre)
                : [...prevGenres, genre];
            return newGenres;
        });
        setCurrentPage(1);
        setHasMore(true);
    };

    const renderGenreFilters = () => (
        <VStack spacing={2} width={'100%'} pb=''>
          <Button 
            alignSelf='start'
            onClick={toggleGenreVisibility} 
            h='20px'
            fontSize='10px'
            mx='7'
            px='8px'
            colorScheme="purple"
            isDisabled={searchType !== 'films'}
          >
            {showGenres ? "Hide Genres" : "Genres"}
          </Button>
          {showGenres && searchType === 'films' && (
            <Flex flexWrap="wrap" justifyContent="center" alignContent="space-between" >
            {genres.map(genre => (
                <Button
                key={genre.id}
                h='20px'
                fontSize='10px'
                px='8px'
                m='1'
                variant={selectedGenres.includes(genre.id) ? "solid" : "outline"}
                bg={selectedGenres.includes(genre.id) ? "purple.600" : ""}
                onClick={() => handleGenreChange(genre.id)}
                >
                {genre.name}
                </Button>
            ))}
            </Flex>
          )}
        </VStack>
    );
    

    const callLogOut = () => {
        try {
            handleLogout();
            setScene('HOME');
        } catch (e) {
            console.log("DED: ", e);
        }
    }

    const renderLoginButton = (loggedIn) => {
        if (!loggedIn) {
            return (
                <MenuItem 
                    letterSpacing='wider' 
                    variant='ghost' 
                    fontFamily="buttonFont" 
                    onClick={() => navigate('/login')}
                    bg="#6c39ac"
                    _hover={{ bg: 'whiteAlpha.200' }}
                    _focus={{ bg: '#6c39ac' }}
                >
                    Login
                </MenuItem>
            );
        } else {
            return (
                <MenuItem
                    letterSpacing='wider'
                    variant='ghost'
                    fontFamily="buttonFont"
                    onClick={() => {
                        navigate(`/profile/${userId}`);
                    }}
                    bg="#6c39ac"
                    _hover={{ bg: 'whiteAlpha.200' }}
                    _focus={{ bg: '#6c39ac' }}
                >
                    Profile
                </MenuItem>
            );
        }
    }

    useEffect(() => {
        isMounted.current = true;
        const fetchNotifications = async () => {
            if (!isMounted.current) return;
            try {
                const response = await getNotifications();
                if (isMounted.current) {
                    setNotifications(response.notifications);
                }
            } catch (error) {
                if (isMounted.current) {
                    console.error('Error fetching notifications:', error);
                }
            }
        };

        const handleRefresh = () => {
            if (isMounted.current) {
                fetchNotifications();
            }
        };

        if (loggedIn) {
            fetchNotifications();
            window.addEventListener('refreshNotifications', handleRefresh);
            const interval = setInterval(fetchNotifications, 60000);
            return () => {
                isMounted.current = false;
                window.removeEventListener('refreshNotifications', handleRefresh);
                clearInterval(interval);
            };
        }
        
        return () => {
            isMounted.current = false;
        };
    }, [loggedIn]);

    const unreadCount = notifications.filter(n => !n.read).length;

    const handleNotificationClick = async (notification) => {
        if (!isMounted.current) return;
        try {
            if (!notification.read) {
                await markNotificationsAsRead([notification._id]);
                // Update local state to mark notification as read
                if (isMounted.current) {
                    setNotifications(prevNotifications =>
                        prevNotifications.map(n =>
                            n._id === notification._id ? { ...n, read: true } : n
                        )
                    );
                }
            }

            setIsActivityOpen(false);

            // Navigate based on notification type
            if (notification.type === 'USER_FOLLOW') {
                navigate(`/profile/${notification.sender._id}`);
            } else if (notification.type === 'PLAYLIST_FOLLOW') {
                navigate(`/profile/${notification.playlistId.user_id}/playlists/${notification.playlistId._id}`);
            }
        } catch (error) {
            console.error('Error handling notification click:', error);
        }
    };

    const getNotificationText = (notification) => {
        const senderName = notification.sender.username;
        
        if (notification.type === 'USER_FOLLOW') {
            return (
                <Text fontSize="sm">
                    <Text as="span" color="pink.400">@{senderName}</Text> followed you
                </Text>
            );
        } else if (notification.type === 'PLAYLIST_FOLLOW') {
            return (
                <Text fontSize="sm">
                    <Text as="span" color="pink.400">@{senderName}</Text> followed your playlist{' '}
                    <Text as="span" color="purple.500">{notification.playlistId.playlistTitle}</Text>
                </Text>
            );
        }
        return '';
    };

    const getFormattedDate = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
        const diffTime = Math.abs(now - date);
        const diffMinutes = Math.floor(diffTime / (1000 * 60));
        const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays > 0) {
            return `${diffDays}d ago`;
        } else if (diffHours > 0) {
            return `${diffHours}h ago`;
        } else if (diffMinutes > 0) {
            return `${diffMinutes}m ago`;
        } else {
            return 'Just now';
        }
    };

    return (
        <>

            <HStack marginTop={'12px'} justifyContent='space-between' width={'100%'}  >
                <VStack >
                    <Button h={'100%'} w={'100%'} variant='ghost' 
                        onClick={() => {onHomeClick()}} >
                            <VStack align='start' spacing='.1px'>
                            <Heading colorScheme ='qTurquoise' variant='custom-tq' fontFamily ="qFont" fontSize='5xl' letterSpacing='tighter'>
                            QUARTILE
                            </Heading>
                            <Heading paddingLeft='8px' fontFamily="categoryFont" fontSize='xs' letterSpacing="widest" paddingRight={'12'} color='pink.400'>
                            Film Rating System 
                            </Heading>
                            </VStack> 
                    </Button>
                </VStack>
                   
                <VStack paddingLeft='16' paddingBottom={'6'}>
                    <Menu isLazy>
                        <Box position="relative">
                            <MenuButton
                                as={IconButton}
                                aria-label='Options'
                                icon={useColorModeValue(<CustomMenuIconBlack/>, <CustomMenuIcon/>)}
                                variant='ghost'
                            />
                            {unreadCount > 0 && (
                                <Badge
                                    position="absolute"
                                    top="-1"
                                    right="4"
                                    colorScheme="purple"
                                    borderRadius="full"
                                    fontSize="xs"
                                    minW="18px"
                                    h="18px"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    color="purple.500"
                                >
                                    {unreadCount}
                                </Badge>
                            )}
                        </Box>

                        <MenuList
                            style={{
                                backgroundColor: '#6c39ac',
                                borderColor: useColorModeValue('black', 'white'),
                                borderWidth: '2px'
                            }} 
                        >
                            {renderLoginButton(loggedIn)}

                            {loggedIn && (
                                <MenuItem
                                    letterSpacing='wider'
                                    variant='ghost'
                                    fontFamily="buttonFont"
                                    onClick={() => setIsActivityOpen(true)}
                                    position="relative"
                                >
                                    Activity {unreadCount > 0 && (
                                        <Badge
                                            ml={2}
                                            colorScheme="purple"
                                            borderRadius="12%"
                                            fontSize="xs"
                                            fontFamily="paragraphFont"
                                        >
                                            {unreadCount}
                                        </Badge>
                                    )}
                                </MenuItem>
                            )}

                            <MenuItem
                                letterSpacing='wider' 
                                variant='ghost' 
                                fontFamily="buttonFont" 
                                onClick={() => {navigate('/about')}}
                            >
                                About
                            </MenuItem>

                            <MenuItem> 
                                <CustomSwitch
                                onColor={'#000'}
                                offColor={'#FFF'}
                                isChecked={customSwitchChecked}
                                handleSwitch={() =>{
                                    setCustomSwitchChecked(!customSwitchChecked);
                                    toggleColorMode();
                                }}
                                >
                                </CustomSwitch>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </VStack>  
            </HStack>

            <Box width={'88%'} pt='5'>
            <form onSubmit={onSearch} >
                       <Input 
                       value={userSearchInput}
                       variant="filled" 
                       placeholder={`search ${searchType}`}
                       _placeholder={{ color: '#94B0B3' }}
                       textAlign='center'
                       width='100%'
                       onChange={onChangeKeyword}
                       onSubmit={onSearch}
                       style={{
                           backgroundColor: '#6c39ac',
                           borderColor: '#6c39ac',
                           borderRadius: '12px',
                       }} 
                       />
            </form>
            </Box>
            <HStack spacing={1}>
                <Text
                    cursor="pointer"
                    onClick={() => handleSearchTypeToggle('films')}
                    fontWeight={searchType === 'films' ? 'bold' : 'normal'}
                    color={searchType === 'films' ? useColorModeValue("purple.500", "purple.500") : useColorModeValue("black", "white")}
                    fontSize="sm"
                >
                    films
                </Text>
                <Text>/</Text>
                <Text
                    cursor="pointer"
                    onClick={() => handleSearchTypeToggle('playlists')}
                    fontWeight={searchType === 'playlists' ? 'bold' : 'normal'}
                    color={searchType === 'playlists' ? useColorModeValue("purple.500", "purple.500") : useColorModeValue("black", "white")}
                    fontSize="sm"
                >
                    playlists
                </Text>
                <Text>/</Text>
                <Text
                    cursor="pointer"
                    onClick={() => handleSearchTypeToggle('profiles')}
                    fontWeight={searchType === 'profiles' ? 'bold' : 'normal'}
                    color={searchType === 'profiles' ? useColorModeValue("pink.400", "pink.400") : useColorModeValue("black", "white")}
                    fontSize="sm"
                >
                    profiles
                </Text>
            </HStack>
            
            {CategoryCheckbox(setCategories, homeClicked, setHomeClicked, searchType === 'films', searchType, searchSubmitted, setSearchSubmitted)}
            {searchType === 'films' && renderGenreFilters()}

            {isActivityOpen && (
                <Modal
                    isOpen={true}
                    onClose={() => setIsActivityOpen(false)}
                    motionPreset="slideInTop"
                    size="full"
                >
                    <ModalOverlay />
                    <ModalContent
                        margin={0}
                        rounded="none"
                        height="100vh"
                        bg="transparent"
                    >
                        <MobileActivity
                            notifications={notifications}
                            onNotificationClick={handleNotificationClick}
                            onClose={() => setIsActivityOpen(false)}
                        />
                    </ModalContent>
                </Modal>
            )}
        </>
    );
};

export default MobileTopNav;