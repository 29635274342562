import React, { useState, useEffect } from 'react';
import {
    HStack,
    VStack,
    Button,
    IconButton,
    Heading, 
    Menu,
    MenuButton,
    useColorModeValue,
    MenuList,
    MenuItem,
    Box,
    Badge,
    Modal,
    ModalOverlay,
    ModalContent,
} from '@chakra-ui/react';
import CustomSwitch from "./CustomSwitch";
import { useNavigate } from 'react-router-dom';
import { getNotifications, markNotificationsAsRead } from '../api';
import MobileActivity from './MobileActivity';

const MobileAltNav = (props) => {

    const {
        loggedIn,
        handleLogout,
        customSwitchChecked,
        setCustomSwitchChecked,
        toggleColorMode,
        userId,
    } = props;

    const navigate = useNavigate();
    const [notifications, setNotifications] = useState([]);
    const [isActivityOpen, setIsActivityOpen] = useState(false);

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const response = await getNotifications();
                setNotifications(response.notifications);
            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        };

        if (loggedIn) {
            fetchNotifications();
            const interval = setInterval(fetchNotifications, 60000);
            return () => clearInterval(interval);
        }
    }, [loggedIn]);

    const unreadCount = notifications.filter(n => !n.read).length;

    const handleNotificationClick = async (notification) => {
        try {
            if (!notification.read) {
                await markNotificationsAsRead([notification._id]);
                setNotifications(prevNotifications =>
                    prevNotifications.map(n =>
                        n._id === notification._id ? { ...n, read: true } : n
                    )
                );
            }

            setIsActivityOpen(false);

            if (notification.type === 'USER_FOLLOW') {
                navigate(`/profile/${notification.sender._id}`);
            } else if (notification.type === 'PLAYLIST_FOLLOW') {
                navigate(`/profile/${notification.playlistId.user_id}/playlists/${notification.playlistId._id}`);
            }
        } catch (error) {
            console.error('Error handling notification click:', error);
        }
    };

    const CustomMenuIcon = () => (
        <img src={process.env.PUBLIC_URL + '/MenuWhite.svg'}
        width="50"
        height="50"
        alt="Menu Icon" />
      );

    const CustomMenuIconBlack = () => (
        <img src={process.env.PUBLIC_URL + '/MenuBlack.svg'}
        width="50"
        height="50"
        alt="Menu Icon" />
    );

    const callLogOut = () => {
        try {
            handleLogout();
            navigate('/');
        } catch (e) {
            console.log("DED: ", e);
        }
    }

    const onHomeClick = () => {
        navigate('/');
    }

    const renderLoginButton = (loggedIn) => {
        if (!loggedIn) {
            return (
                <MenuItem 
                    letterSpacing='wider' 
                    variant='ghost' 
                    fontFamily="buttonFont" 
                    onClick={() => navigate('/login')}
                    bg="#6c39ac"
                    _hover={{ bg: 'whiteAlpha.200' }}
                    _focus={{ bg: '#6c39ac' }}
                >
                    Login
                </MenuItem>
            );
        } else {
            return (
                <MenuItem
                    letterSpacing='wider'
                    variant='ghost'
                    fontFamily="buttonFont"
                    onClick={() => {
                        navigate(`/profile/${userId}`);
                    }}
                    bg="#6c39ac"
                    _hover={{ bg: 'whiteAlpha.200' }}
                    _focus={{ bg: '#6c39ac' }}
                >
                    Profile
                </MenuItem>
            );
        }
    }

    return (
        <>
            <HStack marginTop={'12px'} justifyContent='space-between' width={'100%'}  >
                <VStack >
                    <Button h={'100%'} w={'100%'} variant='ghost' 
                        onClick={() => {onHomeClick()}} >
                            <VStack align='start' spacing='.1px'>
                            <Heading width='200px' colorScheme ='qTurquoise' variant='custom-tq' fontFamily ="qFont" fontSize='5xl' letterSpacing='tighter'>
                            QUARTILE
                            </Heading>
                            <Heading paddingLeft='8px' fontFamily="categoryFont" fontSize='xs' letterSpacing="widest" color='pink.400'>
                            Film Rating System 
                            </Heading>
                            </VStack> 
                    </Button>
                </VStack>
                   
                <VStack paddingLeft='16'paddingBottom={'6'}>
                    <Menu isLazy>
                        <Box position="relative">
                            <MenuButton
                                as={IconButton}
                                aria-label='Options'
                                icon={useColorModeValue(<CustomMenuIconBlack/>, <CustomMenuIcon/>)}
                                variant='ghost'
                            />
                            {unreadCount > 0 && (
                                <Badge
                                    position="absolute"
                                    top="-1"
                                    right="4"
                                    colorScheme="purple"
                                    borderRadius="full"
                                    fontSize="xs"
                                    minW="18px"
                                    h="18px"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    color="purple.500"
                                >
                                    {unreadCount}
                                </Badge>
                            )}
                        </Box>

                        <MenuList
                            style={{
                                backgroundColor: '#6c39ac',
                                borderColor: useColorModeValue('black', 'white'),
                                borderWidth: '2px'
                            }} 
                        >
                            {renderLoginButton(loggedIn)}

                            {loggedIn && (
                                <MenuItem
                                    letterSpacing='wider'
                                    variant='ghost'
                                    fontFamily="buttonFont"
                                    onClick={() => setIsActivityOpen(true)}
                                    position="relative"
                                >
                                    Activity {unreadCount > 0 && (
                                        <Badge
                                            ml={2}
                                            colorScheme="purple"
                                            borderRadius="12%"
                                            fontSize="xs"
                                        >
                                            {unreadCount}
                                        </Badge>
                                    )}
                                </MenuItem>
                            )}

                            <MenuItem
                                letterSpacing='wider' 
                                variant='ghost' 
                                fontFamily="buttonFont" 
                                onClick={() => {
                                    navigate('/about');
                                }}
                            >
                                About
                            </MenuItem>

                            <MenuItem> 
                                <CustomSwitch
                                onColor={'#000'}
                                offColor={'#FFF'}
                                isChecked={customSwitchChecked}
                                handleSwitch={() =>{
                                    setCustomSwitchChecked(!customSwitchChecked);
                                    toggleColorMode();
                                }}
                                >
                                </CustomSwitch>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </VStack>  
            </HStack>

            {isActivityOpen && (
                <Modal
                    isOpen={true}
                    onClose={() => setIsActivityOpen(false)}
                    motionPreset="slideInTop"
                    size="full"
                >
                    <ModalOverlay />
                    <ModalContent
                        margin={0}
                        rounded="none"
                        height="100vh"
                        bg="transparent"
                    >
                        <MobileActivity
                            notifications={notifications}
                            onNotificationClick={handleNotificationClick}
                            onClose={() => setIsActivityOpen(false)}
                        />
                    </ModalContent>
                </Modal>
            )}
        </>
    );
};

export default MobileAltNav;