// This apiUrl fix across all frontend API calls, this is because with react dom router the browser URL route is pre-pended to the fetch() call which breaks our current API calls
// const apiUrl = process.env.NODE_ENV === 'production'
//         ? 'https://quartilefilm.io'
//         : 'http://localhost:8081';

const apiUrl = process.env.REACT_APP_API_URL || 
  (process.env.NODE_ENV === 'production' 
    ? (window.location.hostname === 'quartileweb-staging.herokuapp.com'
        ? 'https://quartileweb-staging.herokuapp.com'
        : 'https://quartilefilm.io')
    : 'http://localhost:8081');

export const registerApi = async ({ email, password, username, fullName }) => {
    try {
        const resp = await fetch(`${apiUrl}/api/auth/register`, {
            method: 'POST',
            body: JSON.stringify({ 
                email, 
                password,
                username,
                fullName
            }),
            headers: { 'Content-Type': 'application/json' },
        });
        
        if (!resp.ok) {
            const respJson = await resp.json();
            throw new Error(respJson.message);
        }

        return resp.json();
    } catch (err) {
        console.log('ERROR calling register: ', err);
        throw err;
    }
}

export const verifyApi = async ({
    token
  }) => {
    try {
     const resp = await fetch(`${apiUrl}/api/auth/verify`, {
        method: 'POST',
        body: JSON.stringify({ token }),
        headers: { "Content-Type": "application/json" },
        
      });
  
      if (!resp.ok) {
        const respJson = await resp.json();
        throw new Error(respJson.message);
      }

      return resp.json();
    } catch (err) {
        console.log('ERROR calling verify: ', err);
        throw err;
    }
  };

export const verifyEmailChangeApi = async ({token}) => {
    try {
        const resp = await fetch(`${apiUrl}/api/auth/verify-email-change`, {
            method: 'POST',
            body: JSON.stringify({ token }),
            headers: { "Content-Type": "application/json" },
        });

        if (!resp.ok) {
            const respJson = await resp.json();
            throw new Error(respJson.message);
        }

        return resp.json();
    } catch (err) {
        console.log('ERROR verifying email change: ', err);
        throw err;
    }
};
  
export const loginApi = async ({ email, password }) => {
    try {
        const resp = await fetch(`${apiUrl}/api/auth/login`, {
            method: 'POST',
            body: JSON.stringify({ email, password }),
            headers: { 'Content-Type': 'application/json' },
        });
        
        if (!resp.ok) {
            const respJson = await resp.json();
            throw new Error(respJson.message);
        }

        return resp.json();
    } catch (err) {
        console.log('ERROR calling login: ', err);
        throw err;
    }
}

export const initiatePasswordResetApi = async ({email}) => {
    try {
        const resp = await fetch(`${apiUrl}/api/auth/initiate-password-reset`, {
            method: 'POST',
            body: JSON.stringify({ email }),
            headers: { 'Content-Type': 'application/json' },
        });

        if (!resp.ok) {
            const respJson = await resp.json();
            throw new Error(respJson.message);
        }

        return resp.json();
    } catch (err) {
        console.log('ERROR calling initiate password reset: ', err);
        throw err;
    }
};

export const passwordResetApi = async ({email, token, newPassword}) => {
    try {
        console.log(`Attempting password reset with URL: ${apiUrl}/api/auth/password-reset`);
        const resp = await fetch(`${apiUrl}/api/auth/password-reset`, {
            method: 'POST',
            body: JSON.stringify({ email, token, newPassword }),
            headers: { 'Content-Type': 'application/json' },
        });

        if (!resp.ok) {
            const respJson = await resp.json();
            throw new Error(respJson.message);
        }

        return resp.json();
    } catch (err) {
        console.log('ERROR calling password reset: ', err);
        throw err;
    }
};

export const logoutApi = async () => {
    try {
        const resp = await fetch(`${apiUrl}/api/auth/logout`, {
            method: 'POST',
            //passing email so we can display email in the logged out toast pop up
            headers: { 'Content-Type': 'application/json' },
        });

        if (!resp.ok) {
            throw new Error('HTTP status ', resp.status);
        }

        return resp.status;
    } catch (err) {
        console.log('ERROR calling logout: ', err);
        throw new Error('ERROR calling logout: ', err);
    }
}

export const getUserProfile = async (userId) => {
    try {
        const resp = await fetch(`${apiUrl}/api/auth/${userId}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });

        if (!resp.ok) {
            const errorText = await resp.text();
            console.error('HTTP Error:', resp.status, errorText);
            throw new Error('HTTP status ' + resp.status);
        }

        return resp.json();
    } catch (err) {
        console.error('ERROR calling Get User Profile: ', err);
        throw new Error('ERROR calling Get User Profile: ' + err.message);
    }
};

// export const searchProfiles = async (searchTerm) => {
//     try {
//         console.log('Searching profiles with term:', searchTerm);
//         const resp = await fetch(`${apiUrl}/api/search-profiles?searchTerm=${encodeURIComponent(searchTerm)}`, {
//             method: 'GET',
//             headers: {'Content-Type': 'application/json'}
//         });

//         console.log('Response status:', resp.status);

//         const text = await resp.text();
//         console.log('Full response text:', text);

//         if (!resp.ok) {
//             console.error('Error response:', text);
//             throw new Error(`HTTP status ${resp.status}: ${text}`);
//         }

//         try {
//             const data = JSON.parse(text);
//             console.log('Parsed profile data:', data);
//             return data;
//         } catch (parseError) {
//             console.error('Error parsing JSON:', parseError);
//             throw new Error(`Failed to parse response as JSON: ${text}`);
//         }
//     } catch (err) {
//         console.error('ERROR calling Search Profiles: ', err);
//         throw err;
//     }
// }

export const searchProfiles = async (searchTerm) => {
    try {
        const resp = await fetch(`${apiUrl}/api/auth/search-profiles?searchTerm=${encodeURIComponent(searchTerm)}`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        });

        if (!resp.ok) {
            const text = await resp.text();
            console.error('Error response:', text);
            throw new Error(`HTTP status ${resp.status}: ${text}`);
        }

        const data = await resp.json();
        // console.log('Received profile data:', data);
        return data;
    } catch (err) {
        console.error('ERROR calling Search Profiles: ', err);
        throw new Error('ERROR calling Search Profiles: ' + err.message);
    }
}

// roll back to this once i fix issues
// export const searchProfiles = async (searchTerm) => {
//     try {
//         const resp = await fetch(`${apiUrl}/api/auth/search-profiles?searchTerm=${encodeURIComponent(searchTerm)}`, {
//             method: 'GET',
//             headers: {'Content-Type': 'application/json'}
//         });

//         if (!resp.ok) {
//             const text = await resp.text(); // Get the response as text
//             console.error('Error response:', text); // Log the full response
//             throw new Error('HTTP status ' + resp.status);
//         }

//         const data = await resp.json();
//         console.log('Received profile data:', data);
//         return data;
//         // return resp.json();
//     } catch (err) {
//         console.log('ERROR calling Search Profiles: ', err);
//         throw new Error('ERROR calling Search Profiles: ' + err);
//     }
// }

export const getProfileImageUrl = async (userId) => {
    try {
      const response = await fetch(`${apiUrl}/api/auth/${userId}/profile-image`);
      if (!response.ok) {
        throw new Error('Failed to fetch image URL');
      }
      return response.json();
    } catch (err) {
      console.error('ERROR fetching profile image URL:', err);
      throw err;
    }
  };

// trying to test this out with the signed url and s3 attempt
// export const getProfileImageUrl = async (userId) => {
//     try {
//       console.log(`Fetching profile image for user: ${userId}`);
//       const url = `${apiUrl}/api/auth/${userId}/profile-image`;
//       console.log(`Fetch URL: ${url}`);
      
//       const resp = await fetch(url, {
//         method: 'GET',
//         headers: { 'Content-Type': 'application/json' },
//         credentials: 'include' // Include this if you're using cookies for authentication
//       });
  
//       if (!resp.ok) {
//         const errorText = await resp.text();
//         console.error(`HTTP error! status: ${resp.status}, message: ${errorText}`);
//         throw new Error(`Failed to fetch profile image: ${resp.status} ${errorText}`);
//       }
  
//       const data = await resp.json();
//       console.log('Received profile image data:', data);
//       return data;
//     } catch (err) {
//       console.error('ERROR fetching profile image URL:', err);
//       throw err;
//     }
//   };

// not sure if we are using this api call or not yet but may want to
// export const getProfileImageUrl = (userId) => `${apiUrl}/api/auth/${userId}/profile-image`;

// export const getProfileImageUrl = async (userId) => {
//     try {
//       const resp = await fetch(`${apiUrl}/api/auth/${userId}/profile-image?${Date.now()}`, {
//         method: 'GET',
//         headers: { 'Content-Type': 'application/json' },
//       });
  
//       if (!resp.ok) {
//         const errorText = await resp.text();
//         console.error('HTTP Error:', resp.status, errorText);
//         throw new Error(`Failed to fetch profile image: ${resp.status} ${errorText}`);
//       }
  
//       // Instead of returning the response directly, we return the URL
//       return `${apiUrl}/api/auth/${userId}/profile-image`;
//     } catch (err) {
//       console.error('ERROR fetching profile image URL:', err);
//       throw new Error('ERROR fetching profile image URL: ' + err.message);
//     }
//   };

export const updateUserProfile = async (userId, data) => {
    try {
        let body;
        let headers = {};

        if (data instanceof FormData) {
            body = data;
            // Don't set Content-Type for FormData, let the browser set it
        } else {
            // If updating password, ensure we're sending the correct structure
            if (data.currentPassword && data.newPassword) {
                body = JSON.stringify({
                    currentPassword: data.currentPassword,
                    newPassword: data.newPassword
                });
            } else if (data.currentEmail && data.newEmail) {
                body = JSON.stringify({
                    currentEmail: data.currentEmail,
                    newEmail: data.newEmail
                });
            } else {
                body = JSON.stringify(data);
            }
            headers['Content-Type'] = 'application/json';
        }

        const resp = await fetch(`${apiUrl}/api/auth/${userId}`, {
            method: 'PATCH',
            body: body,
            headers: headers,
        });

        if (!resp.ok) {
            const respJson = await resp.json();
            throw new Error(respJson.message);
        }

        return resp.json();
    } catch (err) {
        console.error('ERROR calling updateUserProfile: ', err);
        throw err;
    }
};

export const followUser = async (userId) => {
    try {
    //   console.log('Sending follow request for user:', userId);
      const resp = await fetch(`${apiUrl}/api/auth/${userId}/follow`, {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            // not sure if we actually need the auth bearer setup here for this to work?
            // 'Authorization': `Bearer ${localStorage.getItem('token')}` 
        },
      });
  
      if (!resp.ok) {
        const errorData = await resp.json();
        throw new Error(errorData.message || 'Failed to follow user');
      }
  
      return resp.json();
    } catch (err) {
      console.error('Error following user:', err);
      throw err;
    }
  };
  
  export const unfollowUser = async (userId) => {
    try {
      const resp = await fetch(`${apiUrl}/api/auth/${userId}/unfollow`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      });
  
      if (!resp.ok) {
        throw new Error('Failed to unfollow user');
      }
  
      return resp.json();
    } catch (err) {
      console.error('Error unfollowing user:', err);
      throw err;
    }
  };

export const searchTmdbFilms = async (
    keyword,
    genres = []
) => {
    try {
        const url = new URL(`${apiUrl}/api/tmdb/films/${keyword}`);
        if (genres && genres.length > 0) {
            url.searchParams.append('genres', genres.join(','));
        }
        const resp = await fetch(url, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        });

        if (!resp.ok) {
            throw new Error('HTTP status ', resp.status);
        }

        return resp.json();
    } catch (err) {
        console.log('ERROR calling Search TMDB Films: ', err);
        throw new Error('ERROR calling Search TMDB Films: ', err);
    }
}

export const searchTmdbFilmById = async (
    featureFilmId
) => {
    try {
        const resp = await fetch(`${apiUrl}/api/tmdb/film/${featureFilmId}`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        });

        if (!resp.ok) {
            throw new Error('HTTP status ', resp.status);
        }

        return resp.json();
    } catch (err) {
        console.log('ERROR calling Search TMDB Films: ', err);
        throw new Error('ERROR calling Search TMDB Films: ', err);
    }
}

export const fetchGenres = async () => {
  try {
    const response = await fetch(`${apiUrl}/api/tmdb/genres`);
    if (!response.ok) {
      const text = await response.text();
      console.error('Error response:', text);
      throw new Error('HTTP status ' + response.status);
    }
    return response.json();
  } catch (err) {
    console.log('ERROR fetching genres: ', err);
    throw new Error('ERROR fetching genres: ' + err.message);
  }
};

export const getFilmsByGenres = async (genres, page = 1) => {
    try {
        const response = await fetch(`${apiUrl}/api/tmdb/filmsByGenres?page=${page}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ genres, page }),
        });
        if (!response.ok) {
            throw new Error('HTTP status ' + response.status);
        }
        return response.json();
    } catch (err) {
        console.log('ERROR getting films by genres: ', err);
        throw new Error('ERROR getting films by genres: ', err);
    }
};

// export const getFilmsByGenres = async (genres) => {
//     try {
//         const response = await fetch(`${apiUrl}/api/tmdb/filmsByGenres`, {
//             method: 'POST',
//             headers: { 'Content-Type': 'application/json' },
//             body: JSON.stringify({ genres }),
//         });
//         if (!response.ok) {
//             throw new Error('HTTP status ' + response.status);
//         }
//         return response.json();
//     } catch (err) {
//         console.log('ERROR getting films by genres: ', err);
//         throw new Error('ERROR getting films by genres: ', err);
//     }
// };

export const rateFilm = async ({
    user_id,
    filmTmdbId,
    filmTitle,
    filmYear,
    filmQRating,
    filmPlotRating,
    filmActingRating,
    filmCinematographyRating,
    filmNoveltyRating,
    filmEndingRating,
    tmdbImagePath,
    filmOverview,
    genres
}) => {
    try {
        // console.log("ANDREW TEST");
        const resp = await fetch(`${apiUrl}/api/ratings/rate`, {
            method: 'POST',
            body: JSON.stringify({ 
                user_id,
                filmTmdbId,
                filmTitle,
                filmYear,
                filmQRating,
                filmPlotRating,
                filmActingRating,
                filmCinematographyRating,
                filmNoveltyRating,
                filmEndingRating,
                tmdbImagePath,
                filmOverview,
                genres
            }),
            headers: { 'Content-Type': 'application/json' },
        });
        if (!resp.ok) {
            throw new Error('HTTP status ', resp.status);
        }

        return resp.status;
    } catch (err) {
        console.log('ERROR calling rate film: ', err);
        throw new Error('ERROR calling rate film: ', err);
    }
}

export const getUserRating = async ({
    filmTmdbId,
    user_id,
}) => {
    try {
        const resp = await fetch(`${apiUrl}/api/ratings/rate/getUserRating`, {
            method: 'POST',
            body: JSON.stringify({
                filmTmdbId,
                user_id,
            }),
            headers: {'Content-Type': 'application/json'},
        });

        if (!resp.ok) {
            throw new Error('HTTP status ', resp.status);
        }

        return resp.json();
    } catch (err) {
        console.log('ERROR calling Get User Rating: ', err);
        throw new Error('ERROR calling Get User Rating: ', err);
    }
}


export const createQuartileFilm = async ({
    filmTmdbId,
    filmTitle,
    filmYear,
    tmdbImagePath,
    filmOverview,
    genres,
    imdbId
}) => {
    try {
        const resp = await fetch(`${apiUrl}/api/films`, {
            method: 'POST',
            body: JSON.stringify({
                filmTmdbId,
                filmTitle,
                filmYear,
                tmdbImagePath,
                filmOverview,
                genres,
                imdbId
            }),
            headers: {'Content-Type': 'application/json'},
        });

        if (!resp.ok) {
            throw new Error('HTTP status ', resp.status);
        }

        return resp.json();
    } catch (err) {
        console.log('ERROR calling Create Quartile Film: ', err);
        throw new Error('ERROR calling Create Quartile Film: ', err);
    }
}

export const getQuartileFilm = async (filmTmdbId) => {
    try {
        const resp = await fetch(`${apiUrl}/api/films/${filmTmdbId}`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        });

        if (!resp.ok) {
            const errorText = await resp.text(); // Get error message text
            throw new Error(`HTTP status ${resp.status}: ${errorText}`);
        }

        return resp.json();
    } catch (err) {
        console.log('ERROR calling Get Quartile Film: ', err);
        throw new Error(`ERROR calling Get Quartile Film: ${err.message}`);
    }
}


export const getQuartileFilmByQId = async (
    filmId
) => {
    try {
        const resp = await fetch(`${apiUrl}/api/films/qfilms/${filmId}`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        });

        if (!resp.ok) {
            throw new Error('HTTP status ', resp.status);
        }

        return resp.json();
    } catch (err) {
        console.log('ERROR calling Get Quartile Film by Q Id: ', err);
        throw new Error('ERROR calling Get Quartile Film by Q Id: ', err);
    }
}


// export const getQuartileFilm = async (
//     filmTmdbId
// ) => {
//     try {
//         const resp = await fetch(`${apiUrl}/api/films/${filmTmdbId}`, {
//             method: 'GET',
//             headers: {'Content-Type': 'application/json'}
//         });

//         if (!resp.ok) {
//             throw new Error('HTTP status ', resp.status);
//         }

//         return resp.json();
//     } catch (err) {
//         console.log('ERROR calling Get Quartile Film: ', err);
//         throw new Error('ERROR calling Get Quartile Film: ', err);
//     }
// }

export const getTopFilmsByCategoryAndGenre = async (categories, genres) => {
    try {
      const response = await fetch(`${apiUrl}/api/films/topByCategoryAndGenre`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ categories, genres }),
      });
      if (!response.ok) {
        throw new Error('HTTP status ' + response.status);
      }
      return response.json();
    } catch (err) {
      console.log('ERROR getting top films by category and genre: ', err);
      throw new Error('ERROR getting top films by category and genre: ', err);
    }
  };

export const getTopFilmsByCategory = async (categories) => {
    try {
        const resp = await fetch(`${apiUrl}/api/films/topFilmsByCategory`, {
            method: 'POST',
            body: JSON.stringify({
                categories: categories
            }),
            headers: {'Content-Type': 'application/json'}
        });
        return resp.json();
    } catch (err) {
        console.log('ERROR calling get top films by category', err);
    }
}




// need to finish this API piece still
// Need to decide on how we want to filter or call for playlists, with userid or playlist id etc? 
export const getPlaylists = async (userId) => {
    try {
        const resp = await fetch(`${apiUrl}/api/playlists?user_id=${userId}`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        });

        if (!resp.ok) {
            throw new Error(`HTTP status ${resp.status}`);
        }

        return resp.json();
    } catch (err) {
        console.error('ERROR calling Get Playlists:', err);
        throw new Error('ERROR calling Get Playlists:', err);
    }
};

export const getPlaylist = async (playlistId) => {
    try {
      const resp = await fetch(`${apiUrl}/api/playlists/${playlistId}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
  
      if (!resp.ok) {
        throw new Error(`HTTP status ${resp.status}`);
      }
  
      const data = await resp.json();
      if (!data.playlist || !Array.isArray(data.playlist.playlistFilms)) {
        throw new Error('Invalid playlist data structure');
      }
  
      return data;
    } catch (err) {
      console.log('ERROR calling Get Playlist: ', err);
      throw new Error('ERROR calling Get Playlist: ', err);
    }
  };

  export const getFollowedPlaylists = async (userId) => {
    try {
      const resp = await fetch(`${apiUrl}/api/playlists/followed?userId=${userId}`, {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
      });
  
      if (!resp.ok) {
        throw new Error(`HTTP status ${resp.status}`);
      }
  
      return resp.json();
    } catch (err) {
      console.error('ERROR calling Get Followed Playlists:', err);
      throw new Error('ERROR calling Get Followed Playlists:', err);
    }
  };
  

// need to finish this API piece still
export const createPlaylist = async ({ user_id, playlistTitle, playlistFilms }) => {
    try {
        const resp = await fetch(`${apiUrl}/api/playlists/create`, {
            method: 'POST',
            body: JSON.stringify({ 
                user_id,
                playlistTitle,
                playlistFilms,
            }),
            headers: { 'Content-Type': 'application/json' },
        });

        if (!resp.ok) {
            const respJson = await resp.json();
            throw new Error(respJson.message);
        }

        return resp.json();
    } catch (err) {
        console.log('ERROR calling Create Playlist: ', err);
        throw new Error('ERROR calling Create Playlist: ', err);
    }
}

//  Need to finish this API piece
// need to update the update method so it can handle image updates similar to the updateUserProfile calls
export const updatePlaylist = async (
    playlistId, 
    updates
) => {
    try {
        const resp = await fetch(`${apiUrl}/api/playlists/${playlistId}`, {
            method: 'PATCH',
            body: JSON.stringify(updates),
            headers: {'Content-Type': 'application/json'},
        });

        if (!resp.ok) {
            const respJson = await resp.json();
            console.error('Server responded with an error:', respJson);
            throw new Error(respJson.message || 'Failed to update playlist');
        }

        return await resp.json();
    } catch (err) {
        console.log('ERROR calling Update Playlist: ', err);
        throw err;
    }
}

export const followPlaylist = async (playlistId) => {
    try {
      const resp = await fetch(`${apiUrl}/api/playlists/${playlistId}/follow`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      });
  
      if (!resp.ok) {
        throw new Error('Failed to follow playlist');
      }
  
      return resp.json();
    } catch (err) {
      console.error('Error following playlist:', err);
      throw err;
    }
  };
  
  export const unfollowPlaylist = async (playlistId) => {
    try {
      const resp = await fetch(`${apiUrl}/api/playlists/${playlistId}/unfollow`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      });
  
      if (!resp.ok) {
        throw new Error('Failed to unfollow playlist');
      }
  
      return resp.json();
    } catch (err) {
      console.error('Error unfollowing playlist:', err);
      throw err;
    }
  };


export const deletePlaylist = async (
    playlistId
) => {
    try {
        const resp = await fetch(`${apiUrl}/api/playlists/${playlistId}`, {
            method: 'DELETE',
            headers: {'Content-Type': 'application/json'},
        });

        if (!resp.ok) {
            throw new Error(`HTTP status ${resp.status}`);
        }

        return resp.json();
    } catch (err) {
        console.log('ERROR calling Delete Playlist: ', err);
        throw err;
    }
}

  
// delete this route after i reset the datum for the rating count on the front end in Q production
export const updateAllFilmRatingCounts = async () => {
    try {
        const resp = await fetch(`${apiUrl}/api/films/update-film-rating-counts`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        });

        if (!resp.ok) {
            throw new Error('HTTP status ' + resp.status);
        }

        return resp.json();
    } catch (err) {
        console.error('ERROR updating all film rating counts: ', err);
        throw new Error('ERROR updating all film rating counts: ' + err.message);
    }
};

// delete this route after i reset the datum for the user rating count on the front end in Q production
export const updateAllUserRatingCounts = async () => {
    try {
        const resp = await fetch(`${apiUrl}/api/auth/update-user-rating-counts`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        });

        if (!resp.ok) {
            throw new Error('Failed to update user rating counts');
        }

        return resp.json();
    } catch (err) {
        console.error('Error updating user rating counts:', err);
        throw err;
    }
};

// delete this route after i reset the datum for the playlist count on the front end in Q production
export const updateAllUserPlaylistCounts = async () => {
    try {
        const resp = await fetch(`${apiUrl}/api/auth/update-user-playlist-counts`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        });

        if (!resp.ok) {
            throw new Error('HTTP status ' + resp.status);
        }

        return resp.json();
    } catch (err) {
        console.error('ERROR updating all user playlist counts: ', err);
        throw new Error('ERROR updating all user playlist counts: ' + err.message);
    }
};

// delete this route after i reset the datum for the film genres on the front end in Q production
export const updateAllFilmGenres = async () => {
    try {
        const response = await fetch(`${apiUrl}/api/films/updateAllGenres`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'}
        });
        if (!response.ok) {
            throw new Error('HTTP status ' + response.status);
        }
        return response.json();
    } catch (err) {
        console.log('ERROR updating all film genres:', err);
        throw new Error('ERROR updating all film genres: ' + err.message);
    }
};

export const createAllRatingsPlaylists = async () => {
    try {
        const resp = await fetch(`${apiUrl}/api/playlists/create-all-ratings-playlists`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        });

        if (!resp.ok) {
            throw new Error(`HTTP status ${resp.status}`);
        }

        return resp.json();
    } catch (err) {
        console.error('ERROR creating all ratings playlists:', err);
        throw new Error('ERROR creating all ratings playlists:', err);
    }
};

export const updateMissingImagePaths = async () => {
    try {
        const resp = await fetch(`${apiUrl}/api/films/update-missing-image-paths`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        });

        if (!resp.ok) {
            throw new Error(`HTTP status ${resp.status}`);
        }

        return resp.json();
    } catch (err) {
        console.error('ERROR updating missing image paths:', err);
        throw new Error('ERROR updating missing image paths:', err);
    }
};

export const searchPlaylists = async (searchTerm) => {
    try {
        const resp = await fetch(`${apiUrl}/api/playlists/search?searchTerm=${encodeURIComponent(searchTerm)}`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        });

        if (!resp.ok) {
            const text = await resp.text();
            console.error('Error response:', text);
            throw new Error(`HTTP status ${resp.status}: ${text}`);
        }

        return resp.json();
    } catch (err) {
        console.error('ERROR searching playlists:', err);
        throw new Error('ERROR searching playlists: ' + err.message);
    }
};

export const updateAllFilmOverviews = async () => {
    try {
        const response = await fetch(`${apiUrl}/api/films/updateAllOverviews`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'}
        });
        if (!response.ok) {
            throw new Error('HTTP status ' + response.status);
        }
        return response.json();
    } catch (err) {
        console.log('ERROR updating all film overviews:', err);
        throw new Error('ERROR updating all film overviews: ' + err.message);
    }
};

export const getNotifications = async () => {
    try {
        const resp = await fetch(`${apiUrl}/api/notifications`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });

        if (!resp.ok) {
            throw new Error('Failed to fetch notifications');
        }

        return resp.json();
    } catch (err) {
        console.error('Error fetching notifications:', err);
        throw err;
    }
};

export const markNotificationsAsRead = async (notificationIds) => {
    try {
        const resp = await fetch(`${apiUrl}/api/notifications/mark-read`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ notificationIds }),
        });

        if (!resp.ok) {
            throw new Error('Failed to mark notifications as read');
        }

        return resp.json();
    } catch (err) {
        console.error('Error marking notifications as read:', err);
        throw err;
    }
};

export const getStreamingOptions = async (tmdbId) => {
    try {
        const resp = await fetch(`${apiUrl}/api/rapidapi/streaming/${tmdbId}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });

        if (!resp.ok) {
            const errorData = await resp.json();
            throw new Error(errorData.message || `HTTP status ${resp.status}`);
        }

        return resp.json();
    } catch (err) {
        console.error('ERROR fetching streaming options:', err);
        throw err;
    }
};

export const updateAllFilmImdbIds = async () => {
    try {
        const response = await fetch(`${apiUrl}/api/films/updateAllImdbIds`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'}
        });
        if (!response.ok) {
            throw new Error('HTTP status ' + response.status);
        }
        return response.json();
    } catch (err) {
        console.log('ERROR updating all film imdbIds:', err);
        throw new Error('ERROR updating all film imdbIds: ' + err.message);
    }
};