import React from 'react';
import {
    HStack,
    VStack,
    Button,
    Heading,
    Box,
} from '@chakra-ui/react';
import CustomSwitch from "./CustomSwitch";
import { useNavigate,  useLocation } from 'react-router-dom';
import ActivityPopover from './ActivityPopover';

const AltNav = (props) => {
    const {
        page,
        // setScene,
        loggedIn,
        handleLogout,
        customSwitchChecked,
        setCustomSwitchChecked,
        toggleColorMode,
        loggedInUserId,
    } = props;

    const navigate = useNavigate();
    const location = useLocation();

    const callLogOut = () => {
        try {
            handleLogout();
            navigate('/');
            // setScene('HOME');
        } catch (e) {
            console.log("DED: ", e);
        }
    }

    const renderLoginButton = (loggedIn) => {
        if (!loggedIn) {
            return (
                <Button
                    letterSpacing='wider'
                    variant='ghost'
                    fontFamily="buttonFont"
                    onClick={() => {
                        navigate('/login');
                    }}
                >
                    Login 
                </Button>
            );
        } else {
            
            const isCurrentUserProfile = location.pathname === `/profile/${loggedInUserId}`;
            
            return (
                <Button
                    letterSpacing='wider'
                    variant='ghost'
                    fontFamily="buttonFont"
                    onClick={() => {
                        isCurrentUserProfile ? callLogOut() : navigate(`/profile/${loggedInUserId}`);
                    }}
                >
                    {isCurrentUserProfile ? 'Logout' : 'Profile'}
                </Button>
            );
        }
    }

    const renderNotificationButton = (loggedIn) => {
        if (loggedIn) {
            return <ActivityPopover />;
        }
        return null;
    }

   // Check the URL path to determine if the About heading should be rendered
   const renderHeading = () => {
    if (location.pathname === '/about') {
        return (
            <Heading paddingTop={'6'} letterSpacing={'wider'} fontFamily={"paragraphFont"} alignSelf={'center'} style={{width: '50%', marginLeft: '110px'}}>
                About Quartile
            </Heading>
        );
    } else {
        return (
            <>
                {/* No heading for other pages */}
            </>
        );
    }
}



    return (
        <>
            <HStack width={'100%'} spacing='200px' justifyContent={"space-between"} >
                <VStack class= " alphaContainerdiv"  >
                    <Button 
                        id="home_button" 
                        alignSelf={'flex-start'}  
                        minWidth='150px' 
                        variant='ghost' 
                        onClick={() => {
                            navigate('/');
                        }}></Button>
                </VStack>
                {renderHeading(page)}

                <HStack alignItems="start" spacing='-10px'>
                    <Box pt='2'>
                        {renderNotificationButton(loggedIn)}
                    </Box>
                    <VStack spacing={'-4px'}  >
                        {renderLoginButton(loggedIn)}
                        <Button
                            letterSpacing='wider'
                            variant='ghost'
                            fontFamily="buttonFont"
                            onClick={() => {
                                navigate('/about');
                            }}
                        >
                            About
                        </Button>
                        <CustomSwitch
                            onColor={'#000'}
                            offColor={'#FFF'}
                            isChecked={customSwitchChecked}
                            handleSwitch={() =>{
                                setCustomSwitchChecked(!customSwitchChecked);
                                toggleColorMode();
                            }}
                        >
                        </CustomSwitch>
                    </VStack>
                </HStack>
            </HStack>
        </>
    );
};

export default AltNav;