import React, { useState, useEffect, useRef } from 'react';
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    Button,
    VStack,
    Text,
    HStack,
    Box,
    useColorModeValue,
    Badge,
    Icon,
    Divider,
    Image,
} from '@chakra-ui/react';
import { BiBell } from 'react-icons/bi';
import { getNotifications, markNotificationsAsRead } from '../api';
import { useNavigate } from 'react-router-dom';
import ProfileImage from './ProfileImage';

const ActivityPopover = () => {
    const [notifications, setNotifications] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const isMounted = useRef(true);
    const navigate = useNavigate();
    const bgColor = useColorModeValue('white', 'black');
    const hoverBg = useColorModeValue('gray.100', 'gray.900');
    const unreadBg = useColorModeValue('qTurquoise.500', 'qTurquoise.500');
    const borderColor = useColorModeValue('black', 'white');
    const textColor = useColorModeValue('black', 'white');
    const dividerColor = useColorModeValue('gray.200', 'whiteAlpha.200');
    const timestampColor = useColorModeValue('gray.600', 'gray.600');

    const fetchNotifications = async () => {
        if (!isMounted.current) return;
        try {
            const response = await getNotifications();
            if (isMounted.current) {
                setNotifications(response.notifications);
            }
        } catch (error) {
            if (isMounted.current) {
                console.error('Error fetching notifications:', error);
            }
        }
    };

    useEffect(() => {
        isMounted.current = true;
        fetchNotifications();
        const interval = setInterval(fetchNotifications, 60000);
        
        // Add event listener for refresh notifications
        const handleRefresh = () => {
            if (isMounted.current) {
                fetchNotifications();
            }
        };
        window.addEventListener('refreshNotifications', handleRefresh);
        
        return () => {
            isMounted.current = false;
            clearInterval(interval);
            window.removeEventListener('refreshNotifications', handleRefresh);
        };
    }, []);

    const handleNotificationClick = async (notification) => {
        if (!isMounted.current) return;
        try {
            if (!notification.read) {
                await markNotificationsAsRead([notification._id]);
            }
            
            setIsOpen(false);

            // Update local state to mark notification as read
            if (isMounted.current) {
                setNotifications(prevNotifications =>
                    prevNotifications.map(n =>
                        n._id === notification._id ? { ...n, read: true } : n
                    )
                );
            }

            // Navigate based on notification type
            if (notification.type === 'USER_FOLLOW') {
                navigate(`/profile/${notification.sender._id}`);
            } else if (notification.type === 'PLAYLIST_FOLLOW') {
                navigate(`/profile/${notification.playlistId.user_id}/playlists/${notification.playlistId._id}`);
            }
        } catch (error) {
            console.error('Error handling notification click:', error);
        }
    };

    const getNotificationText = (notification) => {
        const senderName = notification.sender.username;
        const formattedUsername = (
            <Text 
                as="span" 
                color="pink.400"
                cursor="pointer"
                onClick={(e) => {
                    e.stopPropagation();
                    setIsOpen(false);
                    navigate(`/profile/${notification.sender._id}`);
                }}
                _hover={{ textDecoration: 'underline' }}
            >
                @{senderName}
            </Text>
        );
        
        if (notification.type === 'USER_FOLLOW') {
            return <>{formattedUsername} followed you</>;
        } else if (notification.type === 'PLAYLIST_FOLLOW') {
            const playlistTitle = (
                <Text
                    as="span"
                    color="qPurple.500"
                >
                    {notification.playlistId.playlistTitle}
                </Text>
            );
            return <>{formattedUsername} followed your playlist {playlistTitle}</>;
        }
        return '';
    };

    const getFormattedDate = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
        const diffTime = Math.abs(now - date);
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
        const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
        const diffMinutes = Math.floor(diffTime / (1000 * 60));

        if (diffDays > 0) {
            return `${diffDays}d ago`;
        } else if (diffHours > 0) {
            return `${diffHours}h ago`;
        } else if (diffMinutes > 0) {
            return `${diffMinutes}m ago`;
        } else {
            return 'Just now';
        }
    };

    const getNotificationImage = (notification) => {
        if (notification.type === 'USER_FOLLOW') {
            return (
                <Box 
                    width="42px" 
                    height="42px" 
                    borderRadius="12%"
                    overflow="hidden"
                    flexShrink={0}
                    borderWidth="2px"
                    borderColor={borderColor}
                >
                    <Image
                        src={notification.sender.profilePic || "https://images.unsplash.com/photo-1478720568477-152d9b164e26?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"}
                        alt="User profile"
                        width="100%"
                        height="100%"
                        objectFit="cover"
                    />
                </Box>
            );
        } else if (notification.type === 'PLAYLIST_FOLLOW') {
            return (
                <Box 
                    width="42px" 
                    height="42px" 
                    borderRadius="12%"
                    overflow="hidden"
                    flexShrink={0}
                    borderWidth="2px"
                    borderColor={borderColor}
                >
                    <Image
                        src={notification.playlistId.playlistFilms?.[0]?.tmdbImagePath 
                            ? `https://image.tmdb.org/t/p/original${notification.playlistId.playlistFilms[0].tmdbImagePath}`
                            : "https://images.unsplash.com/photo-1478720568477-152d9b164e26?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"}
                        alt="First movie cover"
                        width="100%"
                        height="100%"
                        objectFit="cover"
                    />
                </Box>
            );
        }
        return null;
    };

    const unreadCount = notifications.filter(n => !n.read).length;

    return (
        <Popover
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            placement="bottom-end"
        >
            <PopoverTrigger>
                <Button
                    variant="ghost"
                    onClick={() => setIsOpen(!isOpen)}
                    position="relative"
                    p={1}
                    minW="auto"
                    h="auto"
                >
                    <Icon
                        as={BiBell}
                        boxSize={4}
                        color="purple.500"
                    />
                    {unreadCount > 0 && (
                        <Badge
                            colorScheme="purple"
                            position="absolute"
                            top="-1"
                            right=".5em"
                            borderRadius="full"
                            fontSize="xs"
                            minW="14px"
                            h="14px"
                            p={0}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            color="purple.500"
                        >
                            {unreadCount}
                        </Badge>
                    )}
                </Button>
            </PopoverTrigger>
            <PopoverContent 
                width="400px" 
                maxHeight="400px" 
                overflowY="auto" 
                bg={bgColor}
                borderColor={borderColor}
                borderWidth="3px"
                borderRadius="md"
                boxShadow="dark-lg"
            >
                <PopoverBody p={0}>
                    <VStack align="stretch" spacing={0}>
                        {notifications.length === 0 ? (
                            <Text p={4} textAlign="center" color={textColor}>
                                No activity
                            </Text>
                        ) : (
                            notifications
                                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                                .map((notification) => (
                                    <Box
                                        key={notification._id}
                                        p={3}
                                        bg={notification.read ? 'transparent' : unreadBg}
                                        _hover={{ bg: hoverBg }}
                                        cursor="pointer"
                                        onClick={() => handleNotificationClick(notification)}
                                        borderBottom="1px"
                                        borderColor={dividerColor}
                                    >
                                        <VStack align="stretch" spacing={2}>
                                            <HStack spacing={3} justify="space-between" align="center">
                                                <Text fontSize="sm" color={textColor} flex={1} textAlign="left">
                                                    {getNotificationText(notification)}
                                                </Text>
                                                <Text fontSize="xs" color={timestampColor} textAlign="left">
                                                    {getFormattedDate(notification.createdAt)}
                                                </Text>
                                                {getNotificationImage(notification)}
                                            </HStack>
                                        </VStack>
                                    </Box>
                                ))
                        )}
                    </VStack>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};

export default ActivityPopover; 