import React, { useState, useEffect } from 'react';
import {Box, Image, Badge, VStack, Heading, StackDivider, LinkBox, LinkOverlay, useColorModeValue, Button, Spinner, useToast, Tooltip} from '@chakra-ui/react';
import {getQuartileFilm, getUserRating} from '../api';
import { useNavigate, Link } from 'react-router-dom';

const FilmCard = (props) => {

    const [isLoading, setIsLoading] = useState(true);
    const [quartileFilmData, setQuartileFilmData] = useState({});
    const [error, setError] = useState(null);
    const toast = useToast();
    const navigate = useNavigate();
    const {
        filmId= '',
        imagePath = '',
        title = 'defaultTitle',
        year='defaultYear',
        isFeaturedCard= false,
        avgQRating,
        genres,  
        setScene={setScene},
        setSelectedFilm={setSelectedFilm},
        overview,
    } = props;

    const borderColor = useColorModeValue("black", "white");
    const textColor = useColorModeValue("black", "white");
    const bgColor = useColorModeValue("white", "black");


    // Image Logic - HomePage.jsx is passing backdrop img, if not there, pass poster img, if not there use default
    const imageBaseUrl = 'https://image.tmdb.org/t/p/original';
    const defaultImage = 'https://images.unsplash.com/photo-1478720568477-152d9b164e26?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D/2Z4KKcF';
    const fullImgPath = imagePath ? `${imageBaseUrl}${imagePath}` : defaultImage;

    // Year Logic - If no trimmedYear exists display nothing for year
    const trimmedYear = parseInt(year);

    // TODO: Pass in full film object to the Film Card, needs to be set below
    const handleClick = () => {
        const filmDetails = {
            filmId,
            fullImgPath,
            imagePath,
            title,
            year,
            genres,
            isFeaturedFilm: isFeaturedCard
        };
        setSelectedFilm(filmDetails);
        navigate(`/films/${filmId}`);
    };


    //extract qRatingAverage
    // useEffect(() => {
    //     const loadFilmRatings = async () => {
    //         try {
    //             const film = await getQuartileFilm(filmId);
    //             setQuartileFilmData(film);
    //         } catch (err) {
    //             console.error('Error loading film ratings:', err);
    //             setError(err.message);
    //             toast({
    //                 title: 'Error',
    //                 description: `There was an error loading film data: ${err.message}`,
    //                 status: 'error',
    //                 duration: 5000,
    //                 isClosable: true,
    //             });
    //         } finally {
    //             setIsLoading(false);
    //         }
    //     }
    //     loadFilmRatings();
    // }, [filmId, toast]);

    useEffect(() => {
        let isMounted = true;
        const loadFilmRatings = async () => {
            try {
                const film = await getQuartileFilm(filmId);
                if (isMounted) {
                    setQuartileFilmData(film);
                }
            } catch (err) {
                if (isMounted) {
                    console.error('Error loading film ratings:', err);
                    setError(err.message);
                    toast({
                        title: 'Error',
                        description: `There was an error loading film data: ${err.message}`,
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                }
            } finally {
                if (isMounted) {
                    setIsLoading(false);
                }
            }
        }
        loadFilmRatings();
    
        return () => {
            isMounted = false;
        };
    }, [filmId, toast]);

    const formatRating = (rating) => {
        if (rating === 10) {
            return '10';
        } else {
            return rating?.toFixed(1);
        }
    };
    
    const renderQRating = () => {
        if (avgQRating) {
            return formatRating(avgQRating);
        }
        if (quartileFilmData?.filmQRatingAverage) {
            return formatRating(quartileFilmData.filmQRatingAverage);
        }
        return '?';
    }

    const renderFilmCard = (isFeaturedCard) => {
        const cardContent = (
            <VStack class={isFeaturedCard ? "featureFilmCardVstack" : "containerdiv"}>
                <Image
                    src={fullImgPath}
                    alt="Movie Pic Placeholder"
                    class={isFeaturedCard ? "featureFilmCardFormat" : "filmCardFormat"}
                    style={{ border: `3px solid ${borderColor}` }}
                />

                <VStack 
                    class={isFeaturedCard ? "featureQCardFormat" : "QCardFormat"} 
                    style={{ border: `3px solid ${borderColor}`, backgroundColor: bgColor }}
                >
                    <Heading  
                        class={isFeaturedCard ? "featureQCardNumberFormat" : "QCardNumberFormat"}
                        justify-content={isFeaturedCard ? "flex-end" : "flex-end"}
                    >
                        {isFeaturedCard 
                            ? (isLoading ? <Spinner /> : quartileFilmData?.filmQRatingAverage ? quartileFilmData?.filmQRatingAverage.toFixed(1) : '?')
                            : renderQRating()
                        }
                    </Heading>
                    <Image
                        src="QLogoTest1.png"
                        alt="q Logo "
                        class={isFeaturedCard ? "featureQCardIconFormat" : "QCardIconFormat"}
                    />
                </VStack>

                <Heading class={isFeaturedCard ? "featureQCardTitleFormat" : "QCardTitleFormat"}>
                    {title}
                </Heading>
                {trimmedYear && (
                    <Heading class={isFeaturedCard ? 'featureQCardYearFormat' : 'QCardYearFormat'}>
                        {trimmedYear}
                    </Heading>
                )}
            </VStack>
        );

        return (
            <Box 
                as="div" 
                onClick={handleClick}
                style={{ cursor: 'pointer' }}
            >
                <Link 
                    to={`/films/${filmId}`}
                    onClick={(e) => e.stopPropagation()}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                >
                    {cardContent}
                </Link>
            </Box>
        );
    }

    return (
        <Tooltip 
            label={overview}
            bg='qTurquoise.500' 
            color="black"
            fontSize="xs"
            p={2}
            borderRadius="md"
            hasArrow
            placement="bottom"
            maxW="400px"
        >
            <Box>
                {renderFilmCard(isFeaturedCard)}
            </Box>
        </Tooltip>
    );


}

export default FilmCard;