import React, { useEffect, useState } from 'react';
import {
    VStack,
    HStack,
    Image,
    Text,
    Link,
    useColorMode,
    Heading,
    Spinner,
    Box,
    Tooltip,
} from '@chakra-ui/react';
import { getStreamingOptions } from '../api';

// Add quality display helper
const getQualityDisplay = (quality) => {
    switch(quality?.toLowerCase()) {
        case 'uhd':
            return '4K';
        case 'hd':
            return 'HD';
        case 'sd':
            return 'SD';
        default:
            return '';
    }
};

const StreamingServices = ({ tmdbId, onImdbIdReceived }) => {
    const [streamingOptions, setStreamingOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const { colorMode } = useColorMode();

    useEffect(() => {
        let isMounted = true;

        const fetchStreamingOptions = async () => {
            try {
                const data = await getStreamingOptions(tmdbId);
                // console.log('RapidAPI Response:', data);
                
                // The imdbId might be in a different location in the response
                const imdbId = data?.result?.imdbId || data?.streamingInfo?.imdbId || data?.imdbId;
                // console.log('Extracted imdbId:', imdbId);
                
                // Pass imdbId up to parent component if available
                if (imdbId && onImdbIdReceived) {
                    // console.log('Passing imdbId to parent:', imdbId);
                    onImdbIdReceived(imdbId);
                }

                if (isMounted) {
                    // Extract streaming services from the result
                    const services = [];
                    if (data.streamingOptions?.us) {
                        // The US streaming options are now an array
                        data.streamingOptions.us.forEach(option => {
                            services.push({
                                service: option.service.id,
                                name: option.service.name,
                                link: option.link,
                                type: option.type,
                                quality: option.quality,
                                price: option.price?.formatted,
                                images: option.service.imageSet,
                                themeColor: option.service.themeColorCode,
                                addon: option.addon ? {
                                    name: option.addon.name,
                                    images: option.addon.imageSet
                                } : null
                            });
                        });
                    }
                    // Sort services by name and then by price
                    services.sort((a, b) => {
                        if (a.name === b.name) {
                            // If same service, sort by type (subscription first, then rent, then buy)
                            const typeOrder = { subscription: 0, rent: 1, buy: 2 };
                            if (a.type === b.type) {
                                // If same type, sort by quality (HD first)
                                return a.quality === 'hd' ? -1 : 1;
                            }
                            return typeOrder[a.type] - typeOrder[b.type];
                        }
                        return a.name.localeCompare(b.name);
                    });
                    setStreamingOptions(services);
                    setIsLoading(false);
                }
            } catch (err) {
                console.error('Error fetching streaming options:', err);
                if (isMounted) {
                    setError(err.message || 'Failed to fetch streaming options');
                    setIsLoading(false);
                }
            }
        };

        if (tmdbId) {
            fetchStreamingOptions();
        } else {
            if (isMounted) {
                setIsLoading(false);
            }
        }

        return () => {
            isMounted = false;
        };
    }, [tmdbId, onImdbIdReceived]);

    if (!tmdbId) return null;
    if (isLoading) return <Spinner />;
    if (error) return <Text color="red.500">{error}</Text>;
    if (!streamingOptions || streamingOptions.length === 0) return <Text>No streaming options available</Text>;

    // Group options by service ID
    const groupedOptions = streamingOptions.reduce((acc, option) => {
        // Always use the main service ID for grouping
        const serviceId = option.service;
        if (!acc[serviceId]) {
            acc[serviceId] = {
                name: option.name,
                images: option.images,
                options: []
            };
        }
        
        // Add the option to the service group
        acc[serviceId].options.push(option);

        // Sort options within the group
        acc[serviceId].options.sort((a, b) => {
            // For non-addons or between addons, sort by type
            const typeOrder = { subscription: 0, rent: 1, buy: 2 };
            if (a.type !== b.type) {
                return typeOrder[a.type] - typeOrder[b.type];
            }
            
            // If same type, sort by quality (UHD/4K first)
            if (a.quality !== b.quality) {
                if (a.quality === 'uhd') return -1;
                if (b.quality === 'uhd') return 1;
                if (a.quality === 'hd') return -1;
                if (b.quality === 'hd') return 1;
                return 0;
            }
            
            // If same quality, sort by price (cheapest first)
            const aPrice = parseFloat(a.price?.replace(/[^0-9.]/g, '')) || 0;
            const bPrice = parseFloat(b.price?.replace(/[^0-9.]/g, '')) || 0;
            return aPrice - bPrice;
        });

        return acc;
    }, {});

    return (
        <VStack spacing={6} width="100%" align="center" pt={4} pb={10}>
            <Heading size="lg" fontFamily="buttonFont" color="qPurple.400">Where to Watch</Heading>
            <Box 
                width="100%" 
                display="flex"
                flexDirection={{ base: 'column', md: 'row' }}
                alignItems={{ base: 'center', md: 'flex-start' }}
                justifyContent={{ base: 'center', md: 'space-around' }}
                gap={6}
            >
                {Object.entries(groupedOptions).map(([serviceId, serviceData]) => {
                    const logoUrl = colorMode === 'dark' ? 
                        serviceData.images.darkThemeImage : 
                        serviceData.images.lightThemeImage;
                    
                    // Find the best option for each type (subscription, rent, buy)
                    const bestOptions = serviceData.options.reduce((acc, option) => {
                        const key = `${option.type}${option.addon ? '-addon' : ''}`;
                        if (!acc[key] || parseFloat(option.price?.replace(/[^0-9.]/g, '') || '0') < parseFloat(acc[key].price?.replace(/[^0-9.]/g, '') || '0')) {
                            acc[key] = option;
                        }
                        return acc;
                    }, {});

                    // Sort options to ensure addons appear last
                    const sortedOptions = Object.values(bestOptions).sort((a, b) => {
                        // Put addons last
                        if (a.addon && !b.addon) return 1;
                        if (!a.addon && b.addon) return -1;
                        
                        // For non-addons or between addons, sort by type
                        const typeOrder = { subscription: 0, rent: 1, buy: 2 };
                        return typeOrder[a.type] - typeOrder[b.type];
                    });

                    return (
                        <VStack 
                            key={serviceId} 
                            spacing={1}
                            align="center"
                            minW={{ base: "150px", md: "100px" }}
                            maxW="2000px"
                            pb={{ base: 4, md: 0 }}
                        >
                            <Box maxW="100px" mb={2}>
                                <Image
                                    src={logoUrl}
                                    alt={`Watch on ${serviceData.name}`}
                                    objectFit="contain"
                                    height="70px"
                                />
                            </Box>
                            
                            {/* Show sorted options */}
                            {sortedOptions.map((option, index) => (
                                <Tooltip
                                    key={`option-${index}`}
                                    label={option.addon ? 
                                        `This option lets you watch through ${option.addon.name}. You'll need both services, but you can watch everything in one app.` 
                                        : null
                                    }
                                    isDisabled={!option.addon}
                                    placement="top"
                                    hasArrow
                                    bg="qTurquoise.500"
                                    color="black"
                                    fontSize="xs"
                                    px={3}
                                    py={2}
                                    borderRadius="md"
                                    css={{
                                        '--popper-arrow-bg': 'var(--chakra-colors-qTurquoise-500)'
                                    }}
                                >
                                    <Link 
                                        href={option.link}
                                        isExternal
                                        width="100%"
                                        _hover={{ textDecoration: 'none' }}
                                    >
                                        <Box
                                            as="button"
                                            px={2}
                                            py={1}
                                            borderRadius="md"
                                            fontSize="xs"
                                            bg={colorMode === 'dark' ? 'whiteAlpha.200' : 'gray.100'}
                                            color={colorMode === 'dark' ? 'whiteAlpha.900' : 'gray.700'}
                                            _hover={{
                                                bg: colorMode === 'dark' ? 'whiteAlpha.300' : 'gray.200',
                                            }}
                                            textAlign="center"
                                            width="100%"
                                            transition="all 0.2s"
                                        >
                                            {option.type === 'subscription' ? 'Stream' : option.type}
                                            {option.quality === 'uhd' && ' (4K)'}
                                            {option.price ? ` - ${option.price}` : ''}
                                            {option.addon && ` on ${option.addon.name}`}
                                        </Box>
                                    </Link>
                                </Tooltip>
                            ))}
                        </VStack>
                    );
                })}
            </Box>
        </VStack>
    );
};

export default StreamingServices; 