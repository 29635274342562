import React, { useState, useEffect } from 'react';
import {
    Box,
    VStack,
    Text,
    HStack,
    useColorModeValue,
    IconButton,
    Heading,
    Image,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { IoClose } from 'react-icons/io5';

const MobileActivity = ({ notifications, onNotificationClick, onClose }) => {
    const navigate = useNavigate();
    const bgColor = useColorModeValue('white', 'black');
    const textColor = useColorModeValue('gray.800', 'white');
    const borderColor = useColorModeValue('black', 'white');
    const dividerColor = useColorModeValue('gray.200', 'whiteAlpha.200');
    const hoverBg = useColorModeValue('gray.50', 'whiteAlpha.100');
    const unreadBg = 'qTurquoise.500';
    const timestampColor = useColorModeValue('gray.600', 'gray.600');

    // Add state for notifications
    const [localNotifications, setLocalNotifications] = useState(notifications);

    useEffect(() => {
        const handleRefresh = () => {
            // Update local notifications when refresh event is triggered
            setLocalNotifications(notifications);
        };
        window.addEventListener('refreshNotifications', handleRefresh);
        
        return () => {
            window.removeEventListener('refreshNotifications', handleRefresh);
        };
    }, [notifications]);

    const getNotificationText = (notification) => {
        const senderName = notification.sender.username;
        
        if (notification.type === 'USER_FOLLOW') {
            return (
                <Text fontSize="sm" color={textColor}>
                    <Text 
                        as="span" 
                        color="pink.400"
                        onClick={(e) => {
                            e.stopPropagation();
                            onClose();
                            navigate(`/profile/${notification.sender._id}`);
                        }}
                    >@{senderName}</Text> followed you
                </Text>
            );
        } else if (notification.type === 'PLAYLIST_FOLLOW') {
            return (
                <Text fontSize="sm" color={textColor}>
                    <Text 
                        as="span" 
                        color="pink.400"
                        onClick={(e) => {
                            e.stopPropagation();
                            onClose();
                            navigate(`/profile/${notification.sender._id}`);
                        }}
                    >@{senderName}</Text> followed your playlist{' '}
                    <Text as="span" color="purple.500">{notification.playlistId.playlistTitle}</Text>
                </Text>
            );
        }
        return '';
    };

    const getNotificationImage = (notification) => {
        if (notification.type === 'USER_FOLLOW') {
            return (
                <Box 
                    width="42px" 
                    height="42px" 
                    borderRadius="12%"
                    overflow="hidden"
                    flexShrink={0}
                    borderWidth="2px"
                    borderColor={borderColor}
                >
                    <Image
                        src={notification.sender.profilePic || "https://images.unsplash.com/photo-1478720568477-152d9b164e26?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"}
                        alt="User profile"
                        width="100%"
                        height="100%"
                        objectFit="cover"
                    />
                </Box>
            );
        } else if (notification.type === 'PLAYLIST_FOLLOW') {
            return (
                <Box 
                    width="42px" 
                    height="42px" 
                    borderRadius="12%"
                    overflow="hidden"
                    flexShrink={0}
                    borderWidth="2px"
                    borderColor={borderColor}
                >
                    <Image
                        src={notification.playlistId.playlistFilms?.[0]?.tmdbImagePath 
                            ? `https://image.tmdb.org/t/p/original${notification.playlistId.playlistFilms[0].tmdbImagePath}`
                            : "https://images.unsplash.com/photo-1478720568477-152d9b164e26?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"}
                        alt="First movie cover"
                        width="100%"
                        height="100%"
                        objectFit="cover"
                    />
                </Box>
            );
        }
        return null;
    };

    const getFormattedDate = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
        const diffTime = Math.abs(now - date);
        const diffMinutes = Math.floor(diffTime / (1000 * 60));
        const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays > 0) {
            return `${diffDays}d ago`;
        } else if (diffHours > 0) {
            return `${diffHours}h ago`;
        } else if (diffMinutes > 0) {
            return `${diffMinutes}m ago`;
        } else {
            return 'Just now';
        }
    };

    return (
        <VStack 
            align="stretch" 
            spacing={0} 
            bg={bgColor}
            height="100%"
            width="100%"
        >
            <HStack 
                justify="space-between" 
                align="center" 
                px={4} 
                py={3}
                borderBottom="1px"
                borderColor={borderColor}
                bg="purple.500"
            >
                <Heading 
                    size="md" 
                    color="white"
                    fontFamily="buttonFont"
                    letterSpacing="wider"
                >
                    Activity
                </Heading>
                <IconButton
                    icon={<IoClose size="24px" />}
                    variant="ghost"
                    color="white"
                    _hover={{ bg: 'whiteAlpha.200' }}
                    onClick={onClose}
                    aria-label="Close activity"
                />
            </HStack>

            <Box overflowY="auto" height="calc(100% - 60px)">
                {localNotifications.length === 0 ? (
                    <Text p={4} textAlign="center" color={textColor}>
                        No activity
                    </Text>
                ) : (
                    localNotifications
                        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                        .map((notification) => (
                            <Box
                                key={notification._id}
                                p={3}
                                bg={notification.read ? 'transparent' : unreadBg}
                                _hover={{ bg: hoverBg }}
                                cursor="pointer"
                                onClick={() => onNotificationClick(notification)}
                                borderBottom="1px"
                                borderColor={dividerColor}
                            >
                                <VStack align="stretch" spacing={2}>
                                    <HStack spacing={3} justify="space-between" align="center">
                                        <Box flex={1}>
                                            {getNotificationText(notification)}
                                        </Box>
                                        <Text fontSize="xs" color={timestampColor}>
                                            {getFormattedDate(notification.createdAt)}
                                        </Text>
                                        {getNotificationImage(notification)}
                                    </HStack>
                                </VStack>
                            </Box>
                        ))
                )}
            </Box>
        </VStack>
    );
};

export default MobileActivity; 